import axios from 'axios'

export default function request(config){

    // const baseURL =  'http://f.com/'
    const baseURL =  'https://t.fengsheng.love/'

    const instance = axios.create({
        baseURL:baseURL,
    })

    if(config.url === 'UserLogin') {
        //响应拦截
        instance.interceptors.response.use(success=>{
            if((success.data.status === 0) && (success.headers['token'])){
                sessionStorage['token'] = success.headers['token'];
            }
            return success.data
        },err=>{
            return err
        })
    } else if(config.url === 'UserRegister') {
        //z注册请求，不做任何操作
        //响应拦截??????
        instance.interceptors.response.use(success=>{
            return success.data
        },err=>{
            return err
        })
    } else {
        if(!sessionStorage.getItem('token')) {
            //跳转到登录页面
            location.replace('/login')
        }

        //请求拦截
        instance.interceptors.request.use(success=>{
            success.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
            return success
        },err=>{
            return err
        })

        //响应拦截
        instance.interceptors.response.use(success=>{
            return success.data
        },err=>{
            return err
        })
    }

    return instance(config)
}